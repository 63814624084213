<template>
  <div>
    <div class="main-title">组员设置</div>
    <div class="content-list-page page-content">
      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l margin-t-sm">
          <el-input
            style="width: 240px"
            placeholder="名称"
            v-model="searchData.name"
          />
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-input
            style="width: 240px"
            placeholder="用户id"
            v-model="searchData.userId"
          />
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-select
            v-model="searchData.status"
            placeholder="请选择状态"
            size="mini"
          >
            <el-option label="启用" :value="1"> </el-option>
            <el-option label="禁用" :value="0"> </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>

       
        <span class="margin-l-sm fl-r margin-t-sm">
          <router-link to="/flowCrew/add" class="router-link">
            <el-button type="primary" size="small">新增</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0 }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column label="用户ID" property="user_id" width="80">
          </el-table-column>
          <el-table-column property="name" label="名字"> </el-table-column>
          <el-table-column property="nickname" label="用户昵称">
          </el-table-column>
          <el-table-column property="address" label="状态">
            <template slot-scope="scope">
              <el-link :type="scope.row.status == 1 ? 'primary' : 'danger'" @click="changeStatus(scope.row)">{{
                scope.row.status == 1 ? "启用" : "禁用"
              }}</el-link>
            </template>
          </el-table-column>
          <el-table-column property="address" label="头像">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.avatar_url"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column property="creator" label="创建人"> </el-table-column>
          <el-table-column property="created_at" label="创建时间">
          </el-table-column>
          <el-table-column property="updated_at" label="更新时间">
          </el-table-column>

          <el-table-column property="address" label="操作">
            <template slot-scope="scope">
              <!-- <el-link type="primary" >主要链接</el-link> -->
              <router-link
                :to="'/flowCrew/edit/' + scope.row.id"
                class="router-link"
              >
                <el-link type="primary" size="small">编辑</el-link>
              </router-link>
              -
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

  
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      searchData: {},
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      tableData: [],
      dialogVisible: false,
      form: {
        list: [
          {
            name: "",
            count: "",
          },
        ],
        count: 0,
      },
      wxLoading: false,
    };
  },
  methods: {
    ...mapActions("flow", [
      "listMember",
      "statisticsData",
      "delMember",
      "setMemberStatus"
    ]),
    ...mapActions("sales", ["addSalesWx"]),
  async  changeStatus(row){
      let form = {
        id:row.id,
        status:row.status == 1?0:1
      }
      const {res_info} = await this.setMemberStatus(form)
      if(res_info != 'ok')return
      this.getList()
      let msg = row.status == 1?'禁用':'启用'
      this.$message.success(msg+'成功')
    },
    async deleted(row) {
      const { res_info } = await this.delMember({ id: row.id });
      if (res_info != "ok") return;
      this.getList();
      this.$message.success("删除成功");
    },
    async setWeiXinCount() {
      if (this.form.list.length <= 0) {
        this.$message.error("至少填写一项来源");
        return;
      }
      this.wxLoading = true;
      await this.addSalesWx({ list: this.form.list });
      this.wxLoading = false;
      this.$message.success("录入成功");
      this.dialogVisible = false;
    },
    inputCount(e) {
      let count = 0;
      this.form.list.forEach((item) => {
        count += Number(item.count);
      });
      this.form.count = count;
    },
    delList(index) {
      if (this.form.list.length <= 1) {
        this.$message.info("至少填写一个");
        return;
      }
      this.form.list.splice(index, 1);
      let count = 0;
      this.form.list.forEach((item) => {
        count += Number(item.count);
      });
      this.form.count = count;
      console.log(index);
    },
    addList() {
      this.form.list.push({
        name: "",
        count: "",
      });
    },
    search() {
      this.page = 1;
      this.getList();
    },
    setPageSize(value) {
      this.pageSize = value;
      this.getList();
    },

    setPage(value) {
      this.page = value;
      this.getList();
    },

    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.listMember(form);
      this.tableData = data.list;
      this.total = data.total;
      this.loading = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.selectPic {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.selectPic p {
  float: left;
  width: 20%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.selectPic p:first-child {
  cursor: default;
}
</style>
